<template>
  
    <div class="container-fluid mb-5">

        <div 
            class="row">
            <v-tabs
                v-model="section"
                align-tabs="center"
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                class="w-100"
                center-active
                show-arrows >
                <v-tab value="settings">app settings</v-tab>
                <v-tab value="profile">profile</v-tab>
                <v-tab value="sessions">sessions</v-tab>
                <!--v-tab value="console">console</v-tab-->
            </v-tabs>
        </div>

        <div
            v-if='textMessage'
            class='row'>
            <v-alert 
                :text="textMessage" 
                :type="Object.keys(errorState).length > 0 ? 'error' : 'success'"
                icon="mdi-alert-circle-outline" />
        </div>

        <div 
            class="row">
            <div
                class="col-xl-6 col-lg-8 col-md-10 col-12 offset-xl-3 offset-lg-2 offset-md-1 offset-0" >

                <profileView 
                    v-if="section === 'profile'"/>

                <sessionsView 
                    v-if="section === 'sessions'"/>

                <v-container 
                    v-if="section === 'settings'">

                    <v-row>

                        <v-col
                            class="mt-3 col-12">
                            Ultimo aggiornamento: {{ this.lastUpdateData }}
                        </v-col>

                        <v-col
                            class="mb-3 col-12">
                            versione app: {{ versionApp }}
                        </v-col>

                        <div
                            class="col-12 col-lg-10 offset-lg-1">
                            <v-autocomplete
                                @update:modelValue="e => changeLanguage(e)"
                                :items="this.languages"
                                v-model="this.userLanguage"
                                item-title="label"
                                item-value="data"
                                variant="underlined"
                                label="Language" />
                        </div>

                        <div
                            class="col-12 col-lg-10 offset-lg-1">
                            <v-select
                                @update:modelValue="changeScannerCodes"
                                variant="underlined"
                                label="Scan reader code"
                                v-model="scannerCodes"
                                :items="[
                                    'code_128_reader',
                                    'ean_reader',
                                    'ean_8_reader',
                                    'code_39_reader',
                                    'code_39_vin_reader',
                                    'codabar_reader',
                                    'upc_reader',
                                    'upc_e_reader',
                                    'i2of5_reader',
                                    '2of5_reader',
                                    'code_93_reader',
                                ]"
                                multiple
                                persistent-hint
                            ></v-select>
                        </div>

                        <v-divider />

                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="this.$emit('toggleTheme');"
                                :loading="loading"
                                prepend-icon="mdi-theme-light-dark"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="toggle theme"
                                block />
                        </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="this.$store.commit('resetAppData', true)"
                                :loading="loading"
                                prepend-icon="mdi-delete-empty"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="reset" 
                                block />
                        </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="this.$store.commit('updateAppData', this.emitter)"
                                :loading="loading"
                                prepend-icon="mdi-refresh"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="aggiorna" 
                                block />
                            </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="this.$store.commit('logout')"
                                :loading="loading"
                                prepend-icon="mdi-logout"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="logout"
                                block />
                        </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="this.$store.commit('deleteUser')"
                                :loading="loading"
                                prepend-icon="mdi-account-remove-outline"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="cancella profilo" 
                                block />
                        </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="section = 'console'"
                                :loading="loading"
                                prepend-icon="mdi-console-line"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="Console" 
                                block />
                        </v-col>
                        <v-col
                            class="col-12 col-lg-6" >
                            <v-btn
                                @click="test"
                                :loading="loading"
                                prepend-icon="mdi-test-tube"
                                variant="outlined"
                                size="large"
                                type="submit"
                                text="TEST" 
                                block />
                        </v-col>
                        
                    </v-row>

                    <v-row
                        v-if="section === 'console'">
                        <v-col
                            cols="12">

                            <v-textarea
                                :model-value="logsRows"
                                :auto-grow="true"
                                rows="20"
                                disabled
                                no-resize>

                            </v-textarea>
                            
                        </v-col>
                    </v-row>
                </v-container>

                <barcodeScanner 
                    v-if="section === 'cameraScan'" />

            </div>
        </div>
        
    </div>

</template>

<script>

    import profileView from './profileView.vue';
    import sessionsView from './sessionsView.vue';
    import barcodeScanner from './camerascanView.vue';
    import packageInfo from '/package.json';

    export default {

        components: {
            profileView,
            sessionsView,
            barcodeScanner
        },

        data() {
            return {
                versionApp: 0,
                scannerCodes: [],
                section: null,
                editElements: [],
                errorState: {},
                textMessage: false,
                loading: false,
                logsRows: "",
                lastUpdateData: false,
                userLanguage: false,
                languages: [
                    { "data": "ar", "label": "arabic" },
                    { "data": "zh", "label": "chinese" },
                    { "data": "de", "label": "deutsch" },
                    { "data": "en", "label": "english" },
                    { "data": "es", "label": "español" },
                    { "data": "fa", "label": "farsi" },
                    { "data": "fr", "label": "français" },
                    { "data": "de", "label": "german" },
                    { "data": "el", "label": "greek" },
                    { "data": "hi", "label": "hindi" },
                    { "data": "ja", "label": "japanese" },
                    { "data": "ko", "label": "korean" },
                    { "data": "it", "label": "italiano" },
                    { "data": "la", "label": "latin" },
                    { "data": "pl", "label": "polish" },
                    { "data": "ru", "label": "pусский" }
                ],
            }
        },

        emits: ["toggleTheme"],

        methods: {
            async changeLanguage(language = false) {
                if(language)
                    this.userLanguage = language;
                this.$store.commit("saveField", ['language', this.userLanguage]);
                let data = {};
                data.table = 'exkart_genericTable';
                data.values = {};
                data.values.table = 'userProfile';
                data.values.field = 'language';
                data.values.value = this.userLanguage;
                this.$store.commit("insertLocalDatabase", data);
                data = {};
                data.switch = "updateFields";
                data.table = "users";
                data.fields = [{field: 'language', value: language}];
                let user = await this.$store.getters.getLocalTable({table: "exkart_genericTable", where: {table: 'userProfile', field: "user"}});
                user = user[0].value;
                data.find = [{field: 'user', value: user }];
                data.user = user;
                data.session = await this.$store.getters.getField("session");
                data.auth_key = await this.$store.getters.getField("auth_key");
                let result = await this.$store.dispatch("post", data);
                if(!result)
                    this.$store.dispatch("log", "lingua non salvata sul profilo");
                this.$store.commit("updateAppData", this.emitter);
            },

            async changeScannerCodes() {
                let data = {};
                data.table = 'exkart_genericTable';
                data.values = {};
                data.values.table = 'userProfile';
                data.values.field = 'scannerCodes';
                data.values.value = JSON.stringify(this.scannerCodes);
                this.$store.commit("insertLocalDatabase", data);
            },

            async test() {
                console.log(await this.getString(556483));
                this.section = 'cameraScan';
            },

            async showConsole() {

                console.log("ciao prova test");

            }
        },

        async updated() {
            this.section = this.section || this.$router.currentRoute.value.path.substring(1);
            if(this.section === null)
                this.section = this.$router.currentRoute.value.path.substring(1);
        },

        async mounted() {
            this.versionApp = packageInfo.version;
            this.section = this.$router.currentRoute.value.path.substring(1);
            this.lastUpdateData = await this.$store.getters.getLocalTable({table: "exkart_genericTable", where: {table: 'genericData', field: "lastUpdateData"}});
            this.lastUpdateData = this.lastUpdateData[0].value;
            let DateTimeLast = new Date(this.lastUpdateData);
            this.lastUpdateData = DateTimeLast.toLocaleDateString() + " " + DateTimeLast.toLocaleTimeString();
            // this.userLanguage = await this.$store.getters.getField("language");
            this.userLanguage = await this.$store.getters.getLocalTable({table: "exkart_genericTable", where: {table: 'userProfile', field: "language"}});
            if(this.userLanguage.length <= 0)
                this.userLanguage = 'en';
            else
                this.userLanguage = this.userLanguage[0].value;

            let profileCodes = await this.$store.getters.getLocalTable({table: "exkart_genericTable", where: {table: 'userProfile', field: "scannerCodes"}});
            if(profileCodes[0] && profileCodes[0].value && profileCodes[0].value.length > 0) {
                profileCodes = JSON.parse(profileCodes[0].value);
                this.scannerCodes = profileCodes;
            } else
                this.scannerCodes = ['code_128_reader'];
            
        }

    }

</script>