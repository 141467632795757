import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import mitt from 'mitt';
import { db }  from './dexie.js';
const emitter = mitt();

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

// bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './registerServiceWorker'

const theme = store.state.theme;
const vuetify = createVuetify({
    components,
    directives,
    theme,
});

/*const console_log = window.console.log;
window.console.log = async function(...args) {
    let logConsoleData = JSON.stringify(...args, [0,1,2,3,4,5,6,7,8,9,'result','text','details']);
    let localID = await db.exkart_logs.add({string: logConsoleData, date: new Date().toLocaleString()});
    console_log(localID, ...args);
    // args.forEach(arg=>textarea.value += `${JSON.stringify(arg)}\n`);
}*/

const app = createApp(App)
    .use(vuetify)
    .use(store)
    .use(router);

app.config.globalProperties.getString = async (stringa, lingua) => {
    let filter = {};
    if(typeof stringa !== "string")
        stringa = stringa.toString();
    filter.string = stringa;
    if(lingua && typeof lingua === 'string')
        filter.language = lingua;
    else 
        filter.language = store.state.language || 'en';
    let result = await db.exkart_strings.where(filter).toArray();
    if(result.length < 1 && filter.language !== 'en')
        result = await db.exkart_strings.where({string: stringa, language: 'en'}).toArray();
    if(result.length < 1)
        return 'no translate';
    result = result[0]['description'];
    return result;
}
app.config.globalProperties.emitter = emitter;
app.mount('#app');

