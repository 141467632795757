import { createStore } from 'vuex';
import router from './router';
import { db } from "./dexie";

/* === FUNCTIONS === */
function is_json (stringa = false) {
    if(typeof stringa == 'object')
        return stringa;
    try {
        return JSON.parse(stringa);
    } catch(e) {
        return false;
    }
}

/* === VARIABLES === */
// let urlApi = "https://exkart.techysumo.ml/api/service.php"; // old url
const urlApi = "https://deadapi.techysumo.it/exkart"; // new url
const tokenKeyAuth = "1392BD376D5245312E64BE67B8AA2";
let cookies = {};
let savedLanguageCode = false;

// === THEMES === //
const lightTheme = {
    dark: false,
    options: {customProperties: true},
    colors: {
        background: '#FFFFFF',
        value: '#FFFFFF',
        text: '#000000',
        surface: '#FFFFFF',
        primary: '#8a8a8a',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#ff002f',
        info: '#3da2f5',
        success: '#5fb962',
        warning: '#ff981a',
        danger: '#EE3333'
    },
};

const darkTheme = {
    dark: true,
    options: {customProperties: true},
    colors: {
        background: '#aa00aa',
        text: '#FFFFFF',
        value: '#000000',
        surface: '#000',
        primary: '#c9c9c9',
        secondary: '#03DAC6',
        error: '#B00020',
        info: '#2196F3',
        success: '#5fb962',
        warning: '#FB8C00',
        danger: '#EE3333'
    },
};

let theme = { 
    defaultTheme: 'darkTheme',
    themes: { lightTheme, darkTheme }
};

// === COOCKIES === //
cookies.add = (key, arg) => {
    if(typeof arg !== "string")
        return false;
    cookies[key] = arg;
    var date = new Date();
    date.setFullYear(date.getFullYear()+1);
    document.cookie = key+"="+arg+"; expires = "+date.toUTCString() + ";path=/";
};

cookies.remove = (key) => {
    if(typeof cookies[key] === "function")
        return false;
    delete cookies[key];
    document.cookie = key+"= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
};

cookies.reset = () => {

    Object.keys(cookies).filter( e => typeof cookies[e] !== "function" ).forEach( e => {
        delete cookies[e];
        document.cookie = e+"= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    });

}

if(document.cookie !== '') {

    var array_cookie = document.cookie.split(";");
    let a = null;

    array_cookie.forEach( e => {
        a = e.split("=");
        cookies[a[0].trim()] = decodeURIComponent(a[1].trim());
        if(is_json(cookies[a[0].trim()]))
            cookies[a[0].trim()] = is_json(cookies[a[0].trim()]);
    });

    array_cookie = undefined;

}

if(cookies && typeof cookies.defaultTheme === 'string')
    theme.defaultTheme = cookies.defaultTheme;
else
    cookies.add("defaultTheme", theme.defaultTheme);

savedLanguageCode = 'en';
if(db && db.exkart_genericTable && db.exkart_genericTable.where({field: 'language', table: 'genericData'}).count() === 1)
    savedLanguageCode = db.exkart_genericTable.where({field: 'language', table: 'genericData'}).toArray()['value'];
else if(navigator && navigator.language)
    savedLanguageCode = navigator.language.split('-')[0];

// === STORE OBJECT === //
let store = {

    state: {
        urlApi: urlApi,
        login: cookies && cookies.user ? true : false,
        theme: theme,
        language: savedLanguageCode,
    },

    actions: {

        log(context, string) {

            let date = new Date().toLocaleString();
            console.log("[ " + date + " ]: " + string);
            if(!db || !db.exkart_logs)
                return false;
            db
                .exkart_logs
                .add({date: date, string: string});

        },
        
        async post(context, dati) {
            if(!dati.url)
                dati.url = urlApi;
        
            let options = {
                method: "POST",
                body: JSON.stringify(dati),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": tokenKeyAuth,
                },
            };
            const result = await fetch(dati.url, options);
            let response = await result.text();
            if(result.ok !== true)
                return false;
        
            let test_ = is_json(response);
            if(test_ !== false)
                response = test_;
            else 
                return false;
        
            return response;
        },
        
        async checkAuth() {

            if(!cookies
                || !cookies.session 
                || !cookies.user
                || !cookies.auth_key) {
                this.dispatch("log", "dati obbligatori mancanti nei cookies");
                return false;
            }

            let data = {};
            data.user = cookies.user;
            data.session = cookies.session;
            data.auth_key = cookies.auth_key;
            data.switch = "checkAuth";
            if(!await this.dispatch("isConnected"))
                return true;
            let result = await this.dispatch("post", data);
            if(result === false || result.result === false) {
                console.log(result.text || "internal error");
                this.commit("logout");
                return false;
            } else if(result.result === true)
                this.dispatch("log", "auth ok");
            return true;
        
        },

        isConnected() {
            return window.navigator.onLine;
        },

        is_json,

        async isToUpdateData() {
            let lastUpdateData = await db
                .exkart_genericTable
                .where({field: "lastUpdateData", table: "genericData"})
                .toArray();
            let nowDateTime = new Date();
            let diffDataTime = false;
			if(lastUpdateData.length > 0) {
                lastUpdateData = lastUpdateData[0];
                lastUpdateData = new Date(lastUpdateData.value);
                diffDataTime = new Date(nowDateTime.getTime() - lastUpdateData.getTime());
            } else if(lastUpdateData.length === 0)
                lastUpdateData = false;
            
            if(!diffDataTime || (diffDataTime.getUTCDate() - 1) >= 1)
                return true;
            else
                return false;
        },

        async getTranslation(state, stringa) {
            let filter = {};
            if(typeof stringa !== "string")
                stringa = stringa.toString();
            filter.string = stringa;
            filter.language = state.language || 'en';
            let result = await db.exkart_strings.where(filter).toArray();
            if(result.length < 1 && filter.language !== 'en')
                result = await db.exkart_strings.where({string: stringa, language: 'en'}).toArray();
            if(result.length < 1)
                return 'no translate';
            result = result[0]['description'];
            return result;
        }

    },

    getters: {

        getField: (state) => (field) => {
            if(state && state[field])
                return state[field];
            else
                return false;
        },

        getLocalTable: (state) => async (data) => {
            if(!state.login)
                this.commit("logout");
            if(typeof data === "string")
                data = {table: data};
            if(!data.table)
                return false;
            if(!db || !db.table(data.table))
                return false;
            if(data.where)
                return db[data.table].where(data.where).toArray();
            else
                return await db[data.table].toArray();
        },

    },

    mutations: {

        async deleteUser() {

            let stringa = await this.$store.dispatch("getTranslation", 451217);
            if(!confirm(stringa))
                return false;
            if(!await this.dispatch("isConnected"))
                return false;
            let data = {};
            data.switch = "deleteUser";
            data.user = await this.getters.getField("user");
            data.auth_key = await this.getters.getField("auth_key");
            let result = await this.dispatch("post", data);
            if(!result || !result.result) {
                stringa = await this.$store.dispatch("getTranslation", 280855)
                this.dispatch("log", result.text || stringa);
                return false;
            } else if(result.result)
                this.commit("logout");

        },

        async resetAppData(updateData = false) {

            if(!await this.dispatch("isConnected"))
                return false;
            db
                .delete({disableAutoOpen: false})
                .catch( () => this.dispatch("log", "errore eliminazione database locale") )
                .then( () => this.dispatch("log", "database eliminato correttamente") );

            if(updateData)
                this.commit("updateAppData");

        },

        async updateAppData(state, radicalElement = false) {

            this.dispatch("log", "start updateAppData");

            if(radicalElement)
                radicalElement.emit('toggleLoader', {show: true, text: "updating", progress: 0});

            let lastUpdateDataTime =  await db
                .exkart_genericTable
                .where({field: "lastUpdateData", table: "genericData"})
                .toArray();

            if(lastUpdateDataTime.length > 0) {
                lastUpdateDataTime = lastUpdateDataTime[0];
                lastUpdateDataTime = new Date(lastUpdateDataTime.value);
                lastUpdateDataTime = lastUpdateDataTime.toISOString().slice(0, 19).replace('T', ' ');
                console.log(lastUpdateDataTime);
            } else
                lastUpdateDataTime = false;

            // === STRINGS === //
            let data = {};
            data.language = 'en';
            data.switch = "getStrings";
            data.date = lastUpdateDataTime;
            data = await this.dispatch("post", data);
            if(!data || !data.result)
                this.dispatch("log", data.text || 'internal error, pls try later');
            else
                this.commit("insertLocalDatabase", {table: 'exkart_strings', ...data});

            // === STRINGS === //
            if(state && state.language && state.language !== 'en') {
                data = {};
                data.language = 'en';
                data.switch = "getStrings";
                data.date = lastUpdateDataTime;
                data = await this.dispatch("post", data);
                if(!data || !data.result)
                    this.dispatch("log", data.text || 'internal error, pls try later');
                else
                    this.commit("insertLocalDatabase", {table: 'exkart_strings', ...data});
            }

            if(radicalElement)
                radicalElement.emit('toggleLoader', {show: true, text: "updating", progress: 10});

            if(this.state.login) {
                // === PROFILE === //
                data = {};
                data.user = state.user;
                data.auth_key = state.auth_key;
                data.session = state.session;
                data.switch = "getMe";
                data.date = lastUpdateDataTime;
                let result = await this.dispatch("post", data);
                if(!result || !result.result)
                    this.dispatch("log", result.text || 'internal error, pls try later | table profile');
                else
                    Object
                        .keys(result.details)
                        .forEach( e => this.commit("insertLocalDatabase", {table: 'exkart_genericTable', values: {table: 'userProfile', field: e, value: result.details[e] }}) );

                // === SESSIONS === //
                data.switch = "getSessions";
                result = await this.dispatch("post", data);
                if( !result || !result.result )
                    this.dispatch("log", result.text || 'internal error, pls try later | table sessions');
                else
                    this.commit("insertLocalDatabase", {table: 'exkart_sessions', ...result});
            }

            if(radicalElement)
                radicalElement.emit('toggleLoader', {show: true, text: "updating", progress: 80});

            // === update generic table === //
            data = { table: 'genericData', field: 'lastUpdateData', value: new Date().getTime() };
            db
                .exkart_genericTable
                .put(data)
                .then( () => this.dispatch("log", "data aggiornamento: " + data.value) )
                .catch( () => this.dispatch("log", "lastUpdateData non aggiornato") );

            if(radicalElement)
                radicalElement.emit('toggleLoader', {show: false});
        },

        async logout(state) {
            
            if(window.navigator.onLine === false) {
                this.dispatch("log", "il dispositivo non è connesso ad internet, riprovare quando si è collegati ad una rete dati o wifi");
                return false;
            }

            let dati = {};
            dati.user = cookies.user;
            dati.session = cookies.session;
            dati.auth_key = cookies.auth_key;
            dati.switch = "logoutUser";
            this.commit("resetAppData");
            this.commit("saveField", ['user']);
            this.commit("saveField", ['auth_key']);
            this.commit("saveField", ['session']);
            cookies.reset();
            state.login = false;
            router.push("/login");
            let result = await this.dispatch("post", dati);

            if(result === false) {
                this.dispatch("log", "internal error");
                return false;
            }
                
            this.dispatch("log", result.text);
            db.exkart_genericTable.clear();
            return result.result;

        },

        saveField(state, field) {

            if(typeof field[0] !== "undefined")
                field["field"] = field[0];
            else if(typeof field.field === "undefined")
                return false;
            delete field[0];
            if(field && field[1] && !field.value)
                field["value"] = field[1];
            if(typeof field.value === "undefined")
                delete field.value;
            delete field[1];

            if(field && !field.value) {
                delete state[field.field];
                this.dispatch("log", "cancello field "+field.field);
                cookies.remove(field.field);
            } else {
                state[field.field] = field.value;
                this.dispatch("log", "salvo field: "+field.field);
                cookies.add(field.field, field.value);
            }

        },

        async insertLocalDatabase(state, data) {

            if(!data || !data.table || !db || !db[data.table]) {
                this.dispatch("log", "tabella o database non trovato.");
                return false;
            }

            if(data && data.deleteAllTable)
                await db[data.table].clear();

            if(data && !data.values && data.details) {
                data.values = data.details;
                delete data.details;
            } else if(data && !data.values) {
                this.dispatch("log", "valori non trovati o elemento values non compilato");
                return false;
            }

            if(data.values.length === 0)
                return false;

            let localID = false;
            if(Array.isArray(data.values) === true) {
                db[data.table]
                    .bulkPut(data.values)
                    .then(() => this.dispatch("log", "dati tabella "+data.table+" aggiunti correttamente"))
                    .catch( e => this.dispatch("log", 100000 - e.failures.length + " errori nell'inserimento dei dati nella tabella " + data.table));
            } else 
                localID = await db[data.table]
                    .put(data.values)
                    .then( () => this.dispatch("log", "nuovo valore inserito nella tabella: "+data.table+" con id: "+localID))
                    .catch( () => this.dispatch("log", "errore inserimento valore: "+data.table+" con id: "+localID) );
            return true;

        },

        async insertDatabase(state, data) {

            this.commit("inserLocalDatabase", data);
            if(await this.dispatch("isConnected") === false || 1 == 1)
                return false;
            
            data.switch = "insertDatabase";
            let result = await this.dispatch("post", data);
            if(result === false) {
                this.dispatch("log", "errore nella chiamata post");
                return false;
            }

        }

    }

};

Object
    .keys(cookies)
    .filter(e => typeof cookies[e] !== 'function')
    .forEach(e => store.state[e] = cookies[e]);

store = createStore(store);

export default store;
