<template>

  <v-container class="p-3">
    <v-row 
      justify="center"
      align-content="space-around" >

      <v-col cols="12">
        <v-text-field
          :loading="loading"
          @click:prepend-inner="searchItems"
          @input="searchItems"
          v-model="search"
          ref="search"
          label="search element"
          variant="underlined"
          prepend-inner-icon="mdi-magnify"
          hint="scrivi qui per cercare" />
      </v-col>
      
      <v-col
        v-for="(item, index) in searchingItems"
        :key="index"
        cols="12"
        lg="3"
        md="4"
        sm="6" >
        <!-- router-link
          :to="item.path"
          class="nav-link active" 
          aria-current="page" >
            <v-card
              class="text-center rounded-3 d-flex"
              variant="outlined"
              ripple >
              <v-icon
                :icon="item.icon || 'mdi-list-box'"
                style="font-size: 8em"
                class="m-auto text-center"
                cover />
              <v-card-text class="d-flex justify-content-between align-items-center">
                <div class="container" role="button">
                  <div class="d-flex justify-content-between">
                    <span class="fw-bold text-truncate text-uppercase">{{ item.label }}</span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
        </router-link -->

        <v-btn
          :icon="item.icon || 'mdi-list-box'"
          :to="item.path"
          min-height="10em"
          rounded="lg"
          size="x-large"
          variant="outlined"
          class="text-left"
          block
          slim >
          <v-icon
            :icon="item.icon || 'mdi-list-box'"
            style="font-size: 8em"
            cover />
          <span
            style="font-size: 0.9em"
            class="fw-bold text-truncate text-uppercase">
            {{ item.label }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>

  export default {

    data() {
      return {
        search: null,
        loading: false,
        items: [
          { label: "ordini", path: "/orders" },
          { label: "catalogo", path: "/catalog" },
          { label: "clienti", path: "/clients" },
        ],
        searchingItems: null
      }
    },

    name: 'homeView',

    methods: {
      searchItems() {
        this.searchingItems = this.items.filter( 
          e => e.label === this.search 
          || e.path === this.search 
          || e.label.includes(this.search) 
          || e.path.includes(this.search) );
      }
    },

    beforeMount() {

      this.searchingItems = this.items;

    }

  }

</script>