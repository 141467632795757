import { createRouter, createWebHistory } from 'vue-router'
import homeView from './views/homeView.vue';
import loginView from './views/loginView.vue';
import settingsView from './views/settingsView.vue';

const routes = [

  { path: '/login', component: loginView },
  { path: '/register', component: loginView },
  { path: '/register/:id', component: loginView },
  { path: '/logout', component: loginView },
  { path: '/account', component: settingsView },
  { path: '/profile', component: settingsView },
  { path: '/settings', component: settingsView },
  { path: '/sessions', component: settingsView },
  {
    path: '/',
    name: 'home',
    component: homeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ './views/testView.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
