<template>

  <v-card
    v-if="!loader"
    class="rounded-0">

    <v-layout>
      <v-app-bar
        v-if="isLogin" >

        <v-app-bar-nav-icon
          @click="this.showDrawer = !this.showDrawer" />

        <router-link
          class="navbar-brand" 
          to="/" >
          <img
            v-if="this.$vuetify.theme.current.dark === true"
            class="img-fluid"
            style="max-height: 40px;"
            src="./assets/white_nobg.png" />
          <img
            v-if="this.$vuetify.theme.current.dark === false"
            class="img-fluid"
            style="max-height: 40px;"
            src="./assets/black_nobg.png" />
        </router-link>

        <v-spacer></v-spacer>

        <!--div 
          @click="toggleTheme()"
          class="navbar-brand p-2"
          role="button" >
          <v-icon>mdi-shape</v-icon>
        </div>

        <span 
          @click="this.$store.dispatch('logout')" 
          class="navbar-brand p-3" 
          role="button" >
          <v-icon>mdi-export</v-icon>
          {{ this.$store.state.username }}
        </span-->

        <router-link
          v-if="!!this.pwaInstall"
          @click="installPwa"
          class="navbar-brand p-3" 
          role="button"
          to="/settings">
          <v-icon>mdi-download</v-icon>
        </router-link>

        <router-link
          class="navbar-brand p-3" 
          role="button"
          to="/settings">
          <v-icon>mdi-cog</v-icon>
        </router-link>

      </v-app-bar>

      <v-navigation-drawer
        v-if="isLogin"
        v-model="showDrawer"
        temporary >
        <v-list>
          <v-list-item title="Home" prepend-icon="mdi-home" to="/" />
          <v-list-item title="Account" prepend-icon='mdi-account' to="/profile" />
          <v-list-item title="Settings" prepend-icon='mdi-cogs' to="/settings" /> 
          <v-list-item title="Toggle theme" prepend-icon='mdi-theme-light-dark' @click="toggleTheme()" />
          <v-list-item title="Logout" prepend-icon='mdi-logout' @click="this.$store.commit('logout')" />
        </v-list>
      </v-navigation-drawer>

      <v-main class="appcontainer">

        <v-snackbar
          close-delay="2000"
          location="bottom right"
          v-model="mostraNotifica" >

          {{ stringNotifica }}

        </v-snackbar>

        <router-view
          v-if="isLogin"
          @toggleTheme="toggleTheme"
          @toggleLoader="toggleLoader"
          @toggleDrawer="this.showDrawer = !this.showDrawer"
          @emitNotification="console.log('notifica')" />
        <!--@sendNotify="sendNotify"-->

        <loginView
          v-if="isLogin !== true" />

      </v-main>
    </v-layout>
    
  </v-card>

  <v-card
    v-if="loader" >
    
    <v-layout>

      <v-main
        class="appcontainer" >
        <img
          v-if="this.$vuetify.theme.current.dark === true"
          src="./assets/loader_white.gif"
          class="img-fluid m-3" />
        <img
          v-if="this.$vuetify.theme.current.dark === false"
          src="./assets/loader_black.gif"
          class="img-fluid m-3" />
        <v-progress-circular
          v-if="this.loaderProgress === 0"
          :size="50"
          :width="5"
          color="primary"
          class="m-3"
          indeterminate />
        <v-progress-circular
          v-if="this.loaderProgress > 0"
          :size="50"
          :width="5"
          color="primary"
          class="m-3"
          v-model="this.loaderProgress" />
        <span v-if="this.loaderText"> {{ this.loaderText }} </span>
      </v-main>

    </v-layout>

  </v-card>

</template>

<script>

  /* eslint-disable */
  import loginView from './views/loginView.vue';

  export default {

    created() {
      
    },

    name: "app",

    data() {

      return {
        mostraNotifica: false,
        stringNotifica: null,
        showDrawer: false,
        loader: true,
        loaderProgress: 0,
        loaderText: false,
        pwaInstall: false,
      }

    },

    components: {
      loginView
    },
    
    computed: {
      isLogin() {
        return this.$store.getters.getField("login");
      },
    },

    methods: {

      async installPwa() { this.pwaInstall.prompt(); },

      toggleTheme(theme = false) {

        if(theme === false)
          this.$vuetify.theme.name = this.$vuetify.theme.name === 'lightTheme'
            ? 'darkTheme'
            : 'lightTheme';
        else if(typeof theme === 'string') {

          if(theme in this.$vuetify.theme.themes === false) {
            let string = 'il tema non esiste o non é stato caricato';
            this.$store.dispatch("log", string);
            this.stringNotifica = string;
            this.mostraNotifica = true;
            return false;
          }

          this.$vuetify.theme.name = theme;

        }

        this.$store.commit("saveField", ["defaultTheme", this.$vuetify.theme.name]);

      },

      toggleLoader(data = false) {
        if(!data || !data.show)
          this.loader = ! this.loader;
        else if(data && data.show)
          this.loader = data.show;
        if(data && data.text)
          this.loaderText = data.text;
        if(data && data.progress)
          this.loaderProgress = data.progress;
      },

    },

    async beforeMount() {

      await this.$store.dispatch("checkAuth");
      if(!await this.$store.dispatch("isConnected"))
        return false;
      if(await this.$store.dispatch("isToUpdateData"))
        this.$store.commit("updateAppData");

      window.addEventListener('beforeinstallprompt', (e) => {
        this.$store.dispatch("log", "app non installata");

        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();

        // Stash the event so it can be triggered later.
        // deferredPrompt = e;
        
        // Update UI notify the user they can install the PWA
        this.pwaInstall = e;
      });

    },

    async mounted() {

      this.emitter.on("toggleLoader", (data = false) => this.toggleLoader(data));
      this.loader = false;

    },

  }

</script>

<style>

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: var(--v-background-base);
  }

  .appcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
  }

  * {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
  }

  *::-webkit-scrollbar {
      display: none;
  }

</style>
