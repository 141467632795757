<template>

	<div class="barcodeNumber">No barcode detected</div>
	<div id="barcodescanner"></div>

</template>

<script>

	/* eslint-disable */
	import Quagga from 'quagga';

	export default {

		data() {
			return {

				validFormatReader: ['code_128_reader']
				
			}
		},

		name: 'barcodeScanner',

		methods: {
			
		},

		async beforeMount() {

			let profileCodes = await this.$store.getters.getLocalTable({table: "exkart_genericTable", where: {table: 'userProfile', field: "scannerCodes"}});
            profileCodes = profileCodes[0].value;
            if(profileCodes && profileCodes.length > 0) {
                profileCodes = JSON.parse(profileCodes);
                this.validFormatReader = profileCodes;
            } else
                this.validFormatReader = ['code_128_reader'];

		},

		mounted() {

			Quagga.init({
				frequency: 10,
				numOfWorkers: 4, 
				inputStream: {
					// defines rectangle of the detection/localization area
					area: {
						top: "0%",    // top offset
						right: "0%",  // right offset
						left: "0%",   // left offset
						bottom: "0%"  // bottom offset
					},
					// true: only the red color-channel is read
					// default -> false
					singleChannel: true,
					name: 'Live',
					type: 'LiveStream',
					target: document.querySelector('#barcodescanner'), // Elemento DOM dove mostrare il video
				},
				decoder: {
					readers: this.validFormatReader,
				},
				locator: {
					patchSize: "medium", // può essere 'x-small', 'small', 'medium', 'large', 'x-large'
					halfSample: true
				},
				locate: true, // Attiva la localizzazione dei codici a barre
			}, (err) => {
				if (err) {
					console.error(err);
					return;
				}
				this.$store.dispatch("log", 'QuaggaJS avviato con successo');
				Quagga.start();
			});

			// Ascolta i risultati della scansione
			Quagga.onDetected((data) => {
				this.$store.dispatch("log", 'Codice scansionato:' + data.codeResult.code);
				document.querySelector(".barcodeNumber").innerText = data.codeResult.code;
				/*Quagga.offDetected();
				Quagga.stop();*/
			});

		},

		beforeUnmount() {
			Quagga.stop();
			Quagga.offDetected();
		},

	}

</script>

<style>

	#barcodescanner {
		position: relative;
		width: 100%;  /* Imposta la larghezza in base al layout della pagina */
		height: 400px; /* Imposta un'altezza fissa o dinamica */
		background-color: white;
		/*overflow: hidden;*/ /* Assicurati che il contenitore non trabocchi */
	}

	#barcodescanner video,
	#barcodescanner canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.barcodeNumber {
		padding: 1.5em;
	}

</style>